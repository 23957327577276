









































































import { Component, Vue, Watch } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { IMeeting, IZoomMeeting } from '@/interfaces';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';
import Meeting from '@/components/Meeting.vue'
import { zoomApiKey, apiUrl } from '@/env';
import { dispatchUserLogOut } from '@/store/main/actions';


@Component({
  components: {
    Meeting,
  },
})

export default class Dashboard extends Vue {
  token: string = '';

  all_meetings: IMeeting[] | null = [];
  meetings: IMeeting[] | null = [];
  meeting_config: IZoomMeeting | null = null;
  selected: IMeeting | null = null;

  open_meeting_only: boolean = true;
  show_reader: boolean = false;
  show_meeting: boolean = false;

  public headers = [
    {
      text: '整理番号',
      sortable: true,
      value: 'processing_id',
      align: 'left',
    },
    {
      text: 'ミーテリングID',
      sortable: true,
      value: 'meeting_id',
      align: 'left',
    },
    {
      text: 'パスコード',
      sortable: true,
      value: 'meeting_password',
      align: 'left',
    },
    {
      text: 'お客様',
      sortable: true,
      value: 'client_name',
      align: 'left',
    },
    {
      text: '開始時間',
      sortable: true,
      value: 'start_time',
      align: 'left',
    },
    {
      text: 'お客様リクエスト',
      sortable: true,
      value: 'note1',
      align: 'left',
    },
    {
      text: '担当者',
      sortable: true,
      value: 'employee',
      align: 'left',
    },
    {
      text: '対応メモ',
      sortable: true,
      value: 'note2',
      align: 'left',
    },
    {
      text: '処理済み',
      sortable: true,
      value: 'finished',
      align: 'left',
    },
    {
      text: 'Action',
      value: 'id',
    },
  ];

  public async get_meetings() {
    if (this.token != '') {
        //console.log('Token=' + this.token);
        var m = await api.getAllMeetings(this.token, 0, 100);
        //console.log(r.data);
        this.all_meetings = m.data;
        if (this.open_meeting_only){
          this.meetings = this.all_meetings.filter(m => !m.finished);
        } else {
          this.meetings = this.all_meetings
        }
        console.log('Meetings', this.meetings);
    } else {
        //console.log('Token=' + this.token);
        this.meetings = [];
    }
    this.show_reader = true;
  }

  public async mounted() {
    this.token = readToken(this.$store);
    await this.get_meetings();
  }

  public async display_meeting(meeting: IMeeting)　{
    this.selected = meeting;
    const role: number = 1;   // Initiator
    const sig = (await api.genSignature(meeting.processing_id, role)).data;
    // console.log('Signature', sig);
    // console.log('APIKey', zoomApiKey);
    this.meeting_config = {};
    this.meeting_config.apiKey = zoomApiKey;
    // this.meeting_config.leaveUrl = `http://localhost:8080/main/details?id=${meeting.processing_id}`;
    this.meeting_config.leaveUrl = `${apiUrl}/main/details?id=${meeting.processing_id}`;
    
    this.meeting_config.meetingNumber = meeting.meeting_id;
    this.meeting_config.passWord = meeting.meeting_password;
    this.meeting_config.role = role;
    this.meeting_config.userEmail = '';
    this.meeting_config.userName = 'RBB';
    this.meeting_config.signature = sig;

    this.show_meeting = true;
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

  @Watch('open_meeting_only')
  onDisplayChange(newValue: boolean, oldValue:boolean){
    if (newValue){
      this.meetings = this.all_meetings!.filter(m => !m.finished);
    } else {
      this.meetings = this.all_meetings
    }
  }

}
