







// import axios from "axios";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IMeeting, IZoomMeeting } from '@/interfaces';

declare module 'vue/types/vue' {
  interface Vue {
    ZoomMtg: any;
  }
}

@Component
export default class Meeting extends Vue{
  @Prop({ default: null })
  meeting_config!: IZoomMeeting|null;

  // @Prop({ default: 1})
  // role!: number;

  public created () {
    this.ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.1/lib', '/av');
    this.ZoomMtg.preLoadWasm();
    this.ZoomMtg.prepareJssdk();
  }

  public mounted() {
    this.ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });
  }

  // data () {
  //   return {
  //     apiKey: "",
  //     leaveUrl: "http://localhost:8080",
  //     meetingNumber: "123456789",
  //     passWord: "",
  //     role: 0,
  //     signatureEndpoint: "",
  //     userEmail: "",
  //     userName: "Vue.js"
  //   }
  // },
  // methods: {
    // public getSignature() {
    //   axios.post(this.config!.signatureEndpoint, {
    //     meetingNumber: this.config!.meetingNumber,
    //     role: this.config!.role
    //   })
    //   .then(res => {
    //     console.log(res.data.signature);
    //     this.startMeeting(res.data.signature);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    // }

    public startMeeting() {
      document.getElementById("zmmtg-root")!.style.display = "block";

      // console.log('APIKey:', this.meeting_config!.apiKey);

      this.ZoomMtg.init({
        leaveUrl: this.meeting_config!.leaveUrl,
        isSupportAV: true,
        isSupportChat: false,
        isSupportQA: false,
        isSupportPolling: false,
        success: (success) => {
          console.log(success);
          this.ZoomMtg.join({
            meetingNumber: this.meeting_config!.meetingNumber,
            userName: this.meeting_config!.userName,
            signature: this.meeting_config!.signature,
            apiKey: this.meeting_config!.apiKey,
            userEmail: this.meeting_config!.userEmail,
            passWord: this.meeting_config!.passWord,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            }
          });
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  // }
}
